import React, { Component, useEffect } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import PageHeader from "../components/pageHeader"
import placeholder1 from "../images/placeholder1.jpg"
import PlayButton from "../components/play-button"
import Modal from "react-modal"
import { FaPlayCircle, FaPause } from "react-icons/fa"
import { navigate } from "gatsby"
import { IoIosClose } from "react-icons/io"
import FsLightbox from "fslightbox-react"
import VideoCover from "../components/video-cover"
import CloseButton from "../components/close-button"
import ProjectNavigator from "../components/project-navigator"
import SEO from "../components/seo"
import UseMedia from "../components/use-media"

class CommercialPage extends React.PureComponent {
  state = {
    infoOpen: false,
    modalIsOpen: true,
    videoPlaying: false,
    isDesktop: true,
  }

  componentDidMount = () => {
    this.getCommercials()
  }

  toggleModal = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen })
  }

  toggleVideo = () => {
    this.setState({ videoPlaying: !this.state.videoPlaying })
  }

  toggleInfo = () => {
    this.setState({ infoOpen: !this.state.infoOpen })
  }

  toggleMedia = val => {
    this.setState({ isDesktop: val })
  }

  handleReadMore = () => {
    if (typeof window !== "undefined") {
      window.scrollTo({
        top: 700,
        left: 0,
        behavior: "smooth",
      })
    }
  }

  stopVideo = () => {
    this.setState({ videoPlaying: false })
  }

  getCommercials = () => {
    const pages = this.props.data.allSitePage.edges
    //Get the urls in an array
    const paths = pages.map(page => page.node.path)

    //Get the index of the current page
    const currentPage = paths.indexOf(this.props.path)

    //Look ahead and before to determine if the pages are also commercials
    const ahead = paths[currentPage + 1] || ""
    const before = paths[currentPage - 1] || ""

    var previousPath
    var nextPath

    if (before.startsWith("/commercials/") && before.length > 13) {
      previousPath = before
    } else {
      previousPath = null
    }

    if (ahead.startsWith("/commercials/") && ahead.length > 13) {
      nextPath = ahead
    } else {
      nextPath = null
    }

    //Create the object to return
    const navigation = {
      previous: previousPath,
      next: nextPath,
    }

    //Return object with before and after paths
    return navigation
  }

  render() {
    const data = this.props.data.wordpressPost
    // console.log(data)
    return (
      <Layout hideNav={this.state.videoPlaying}>
        <SEO title={data.title} description={data.acf.short_description} />
        <UseMedia toggleMedia={this.toggleMedia} />
        <PageHeader
          link
          hide={this.state.videoPlaying}
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/commercials")}
        >
          Commercials
        </PageHeader>
        {!this.state.videoPlaying && (
          <ProjectNavigator navigation={this.getCommercials()} />
        )}
        {!this.state.videoPlaying ? (
          <PlayButton>
            <FaPlayCircle
              onClick={() =>
                this.state.isDesktop ? this.toggleVideo() : this.toggleModal()
              }
              size={60}
              color="white"
              opacity={0.9}
            />
          </PlayButton>
        ) : (
          <CloseButton>
            <IoIosClose
              onClick={() => this.toggleVideo()}
              size={80}
              color="white"
            />
          </CloseButton>
        )}
        <FsLightbox
          toggler={this.state.modalIsOpen}
          sources={[data.acf.video_embed_url]}
          type="youtube"
        />
        <MainContainer>
          {data.acf.cover && (
            <Cover
              infoOpen={this.state.infoOpen}
              src={data.acf.cover.source_url}
            />
          )}
          {this.state.videoPlaying && (
            <VideoCover
              stopVideo={this.stopVideo}
              url={data.acf.video_embed_url}
            />
          )}
          <MainContent fade={this.state.videoPlaying}>
            <Title>{data.acf.project_title}</Title>
            <Theme style={{ color: data.acf.theme_colour || "#FFFFFF" }}>
              {data.acf.project_theme}
            </Theme>
            <Client>Client: {data.acf.client}</Client>
            <ShortDesc>{data.acf.short_description}</ShortDesc>
            <MoreButton onClick={() => this.toggleInfo()}>Read More</MoreButton>
            <MoreButton mobile onClick={() => this.handleReadMore()}>
              Read More
            </MoreButton>
          </MainContent>
          <HiddenContent
            fade={this.state.videoPlaying}
            infoOpen={this.state.infoOpen}
          >
            <MoreGradient></MoreGradient>
            <CloseContainer>
              <IoIosClose
                onClick={() => this.toggleInfo()}
                size={40}
                color="white"
              />
            </CloseContainer>
            <ProjectDetails>
              <LongDesc
                dangerouslySetInnerHTML={{
                  __html: data.acf.long_description,
                }}
              />
            </ProjectDetails>
            <TeamContainer>
              <Header>Project Team</Header>
              <Team
                dangerouslySetInnerHTML={{
                  __html: data.acf.project_team,
                }}
              />
            </TeamContainer>
            <AwardsContainer>
              <AwardsImg />
              {data.acf.award_1 && <Header>Awards</Header>}
              {data.acf.award_1 && (
                <Award
                  dangerouslySetInnerHTML={{
                    __html: data.acf.award_1,
                  }}
                />
              )}
              {data.acf.award_2 && (
                <Award
                  dangerouslySetInnerHTML={{
                    __html: data.acf.award_2,
                  }}
                />
              )}
              {data.acf.award_3 && (
                <Award
                  dangerouslySetInnerHTML={{
                    __html: data.acf.award_3,
                  }}
                />
              )}
              {data.acf.award_4 && (
                <Award
                  dangerouslySetInnerHTML={{
                    __html: data.acf.award_4,
                  }}
                />
              )}
              {data.acf.award_5 && (
                <Award
                  dangerouslySetInnerHTML={{
                    __html: data.acf.award_5,
                  }}
                />
              )}
            </AwardsContainer>
          </HiddenContent>
        </MainContainer>
        <ContentContainer>
          <ProjectDetails>
            <LongDesc
              dangerouslySetInnerHTML={{
                __html: data.acf.long_description,
              }}
            />
          </ProjectDetails>
          <TeamContainer>
            <Header>Project Team</Header>
            <Team
              dangerouslySetInnerHTML={{
                __html: data.acf.project_team,
              }}
            />
          </TeamContainer>
          <AwardsContainer>
            <AwardsImg />
            <Header>Awards</Header>
            {data.acf.award_1 && (
              <Award
                dangerouslySetInnerHTML={{
                  __html: data.acf.award_1,
                }}
              />
            )}
            {data.acf.award_2 && (
              <Award
                dangerouslySetInnerHTML={{
                  __html: data.acf.award_2,
                }}
              />
            )}
            {data.acf.award_3 && (
              <Award
                dangerouslySetInnerHTML={{
                  __html: data.acf.award_3,
                }}
              />
            )}
            {data.acf.award_4 && (
              <Award
                dangerouslySetInnerHTML={{
                  __html: data.acf.award_4,
                }}
              />
            )}
            {data.acf.award_5 && (
              <Award
                dangerouslySetInnerHTML={{
                  __html: data.acf.award_5,
                }}
              />
            )}
          </AwardsContainer>
        </ContentContainer>
      </Layout>
    )
  }
}
export default CommercialPage

const MainContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5%;
  overflow: hidden;
`
const ContentContainer = styled.div`
  padding: 5%;
  /* display: grid; */
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 100px;
  display: none;

  @media (max-width: 769px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 750px) {
    display: grid;
  }
`
const ModalContent = styled.div`
  max-width: 550px;
  margin: 0 auto;
  text-align: center;
`
const MainContent = styled.div`
  align-self: flex-end;
  opacity: ${props => (props.fade ? 0 : 1)};
  transition: 0.3s ease-in-out;
`
const HiddenContent = styled.div`
  opacity: ${props => (props.infoOpen ? 1 : 0)};
  opacity: ${props => props.fade && 0};
  transition: 0.3s ease-in-out;
  height: 90vh;
  overflow-y: scroll;
  margin-right: -5%;
  padding-right: 5%;
  width: 450px;
  position: relative;
  padding-bottom: 100px;

  @media (max-width: 750px) {
    display: none;
  }
`
const MoreGradient = styled.div`
  position: fixed;
  width: 100%;
  height: 180px;
  pointer-events: none;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
`
const CloseContainer = styled.div`
  margin-left: -10px;
  margin-top: 40px;
  /* cursor: pointer; */

  svg {
    cursor: pointer;
    transition: 0.2s ease-in-out;
    :hover {
      transform: scale(1.3);
    }
  }
`
const Cover = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100vh;
  width: 100%;
  object-fit: cover;
  margin: 0;
  filter: ${props => props.infoOpen && "brightness(0.3)"};
  transition: 0.3s ease-in-out;
`
const Title = styled.h1`
  color: black;
  background-color: white;
  width: fit-content;
  padding: 5px 10px;
  font-family: "alternategothic2_btregular";
  text-transform: uppercase;
  font-size: 30px;
  margin-bottom: 10px;
`
const Theme = styled.p`
  margin-bottom: 0;
  font-weight: bold;
  font-size: 18px;
`
const Client = styled.p`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
`
const ShortDesc = styled.p`
  max-width: 400px;
  margin-bottom: 0;
  font-family: "Lato";
  font-weight: 500;
  font-size: 18px;
`
const MoreButton = styled.button`
  background: none;
  text-decoration: underline;
  border: none;
  color: white;
  font-family: "alternategothic2_btregular";
  padding: 0;
  margin-top: 10px;
  display: ${props => (props.mobile ? "none" : "block")};
  cursor: pointer;
  :focus {
    outline: none;
  }

  @media (max-width: 750px) {
    display: ${props => (props.mobile ? "block" : "none")};
  }
`
const ProjectDetails = styled.div``
const Header = styled.h1`
  color: white;
  font-family: "alternategothic2_btregular";
  text-transform: uppercase;
  font-size: 19px;
  margin-bottom: 10px;
`
const LongDesc = styled.p`
  font-size: 16px;
  line-height: 26px;
  /* margin-top: 25px; */
  max-width: 550px;
  font-family: "Lato";
    font-weight: 400;
}
`
const TeamContainer = styled.div``
const Team = styled.p`
  font-size: 16px;
  line-height: 21px;
  font-family: "Lato";
  font-weight: 400;

  p {
    margin-bottom: 5px;
  }
`
const AwardsImg = styled.img``
const AwardsContainer = styled.div``
const Award = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-family: "Lato";
  margin-bottom: 20px;

  p {
    margin-bottom: auto;
  }
`

export const postsQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      slug
      acf {
        project_title
        project_theme
        theme_colour
        client
        short_description
        long_description_title
        long_description
        project_team
        award_1
        award_2
        award_3
        award_4
        award_5
        cover {
          source_url
        }
        video_embed_url
      }
    }
    allSitePage {
      edges {
        node {
          path
        }
      }
    }
  }
`
